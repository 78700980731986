import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Top from "../topbutton/Top";
import Profile from "../profile/Profile";
import css from "./ProtectedRoute.module.css";
export default function ProtectedRoute2() {
  return (
    <div>
      <Header />

      <div className={css.container}>
        <h2>Protected Page 2</h2>
        <p className={css.subTitle}> For communication with ZTM my ❤️</p>
      </div>
      <Profile />

      <Footer />
      <Top />
    </div>
  );
}
