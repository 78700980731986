import React from "react";
import "./Footer.css";
import { Fade } from "react-awesome-reveal";
import emoji from "react-easy-emoji";

const currentYear = new Date().getFullYear();

export default function Footer() {
  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div">
        <p className="footer-text">
          {emoji("© ") + currentYear + " Omar Iqbal Kantroo"}
        </p>
      </div>
    </Fade>
  );
}
