/* Change this file to get your personal Portfolio */

// Your Summary And Greeting Section

import emoji from "react-easy-emoji";

const greeting = {
  /* Your Summary And Greeting Section */
  username: "Omar Kantroo",
  title: "Hi all, I'm Omar",
  subTitle: emoji(
    "A coding enthusiast 💻 with about 3.5 yrs of experience in Software development using various Javascript and TypeScript FE and BE frameworks in addition to using AWS suite and PostgreSQL and MongoDB for the Database"
  ),
  resumeLink: "https://github.com/metheok/CV/raw/master/omarKantroo_cv.pdf",
};

// Your Social Media Link
const cvLinks = {
  cvLink1: "https://github.com/metheok/CV/raw/master/omarKantroo_cv.pdf",
};
const socialMediaLinks = {
  github: "https://github.com/metheok",
  linkedin: "https://www.linkedin.com/in/okantroo/",
  telegram: "https://t.me/iamomariqbal",
  gmail: "eyetinok@gmail.com",
  // gitlab: "https://gitlab.com/metheok",
  // facebook: "https://www.facebook.com/saad.pasta7",
  // medium: "https://medium.com/@metheok",
  // stackoverflow: "https://stackoverflow.com/users/10422806/saad-pasta"
  // Instagram and Twitter are also supported in the links!
};

// Your Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop, maintain and deploy full fledged webapps using JavaScript and TypeScript"
    ),
    emoji("⚡ Develop and maintain fast SPA's with integration with database"),
  ],

  /* Make Sure You include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5",
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt",
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass",
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js",
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react",
    },
    {
      skillName: "ASP.NET(MVC)",
      fontAwesomeClassname: "fas fa-code",
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node",
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws",
    },
    {
      skillName: "vuejs",
      fontAwesomeClassname: "fab fa-vuejs",
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm",
    },
    {
      skillName: "Mongo DB",
      fontAwesomeClassname: "fas fa-database",
    },
    {
      skillName: "mySQL",
      fontAwesomeClassname: "fas fa-database",
    },
  ],
};

// Your top 3 proficient stacks/tech experience

// const techStack = {
//   viewSkillBars: true, //Set it to true to show Proficiency Section
//   experience: [
//     {
//       Stack: "Frontend/Design",  //Insert stack or technology you have experience in
//       progressPercentage: "60%"  //Insert relative proficiency in percentage
//     },
//     {
//       Stack: "Backend",
//       progressPercentage: "0%"
//     },
//     {
//       Stack: "Programming",
//       progressPercentage: "60%"
//     }
//   ]
// };

// Your top 3 work experiences

const workExperiences = {
  viewExperiences: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Web Developer",
      company: "Web Artisans",
      websiteLink: "https://www.webartisans.dev/",
      companylogo: require("./assets/images/webArtisans.jfif"),
      date: "December 2021 – Present",
      desc: "Service based Company. Making marketplace for clients. Worked in technologies like ReactJS, NextJS, NestJS, Typescript, PostgreSQL, Redux, NodeJS, ExpressJS, MongoDB etc.",
    },
    // {
    //   role: "Web Developer (Part Time - Contract)",
    //   company: "Infosec Future Pvt Ltd",
    //   websiteLink: "https://infosec-future.com/",
    //   companylogo: require("./assets/images/infosec.png"),
    //   date: "July 2023 – Sept 2023",
    //   desc: "Developed Products in MERN from scratch. Working in technologies like ReactJS, Redux, NodeJS, ExpressJS, MongoDB etc.",
    // },
    {
      role: "Full Stack Developer",
      company: "Possier",
      websiteLink: "https://www.possier.com/",
      companylogo: require("./assets/images/possier-logo.jfif"),
      date: "February 2021 – November 2021",
      desc: "Worked full-stack on development and maintainence resturant specific ERP-POS products. ",
    },
    {
      role: "Software Developer",
      company: "ITS - Internet Travel Solutions",
      websiteLink: "https://www.its.com/",
      companylogo: require("./assets/images/its-logo.png"),
      date: "October 2020 – February 2021",
      desc: "Worked full-stack on development and maintainence online travel products",
    },
    {
      role: "Web Developer(Front End and Backend)",
      company: "ITenzyme",
      websiteLink: "https://www.itenzyme.in/",
      companylogo: require("./assets/images/ITenzymeLogo.jpg"),
      date: "June 2015 – Present",
      desc: "Work experience of over 6+ Years in web design and development, Worked with companies of all magnitudes ranging from a small startup to buzzing product based company",
    },
  ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken:
    "NmFiMWU1NDk3NDIyNzIxYmJiZTgxZDAyMGI4MjllMjRlN2IxMjQxZQ==",
  githubUserName: "metheok", // Change to your github username to view your profile in Contact Section.
  showGithubProfile: "false", // Set true or false to show Contact profile using Github, defaults to false
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  twitter: "https://twitter.com/iamomariqbal",
  location: "Kashmir, India",
  telegram: "https://t.me/iamomariqbal",
  email_address: "eyetinok@gmail.com",
};

export {
  greeting,
  cvLinks,
  socialMediaLinks,
  skillsSection,
  workExperiences,
  openSource,
  contactInfo,
};
