import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Main from "./containers/Main";
import ProtectedRoute from "./containers/ProtectedRoute/ProtectedRoute";
import ProtectedRoute2 from "./containers/ProtectedRoute/ProtectedRoute2";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <Main />
      </div>
    ),
  },
  {
    path: "/kfxQjWlfB6",
    element: (
      <div>
        <ProtectedRoute />
      </div>
    ),
  },
  {
    path: "/NzefhQT4fONoH0bdn2R5unjNCSFtH9DG",
    element: (
      <div>
        <ProtectedRoute2 />
      </div>
    ),
  },
]);
function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
